@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}


@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Aurora';
  src: url('../fonts/Aurora.otf') format('opentype');
}

body {
  font-family: "DM Sans", sans-serif;
}

.landing-page {
  font-family: "Montserrat" !important;
}

.landing-page h1 {
  font-family: "Aurora" !important;
  font-weight: 300;
  letter-spacing: 5px;
}

option {
  color: black;
}

button.chakra-button {
  border-radius: 0;
  font-size: 13px;
  padding: 10px 15px;
  height: auto;
}

span.chakra-button__icon {
  margin-right: 3px;
  font-size: 15px;
}

.table-row-hover:hover {
  background-color: #eaeaea;
}

.phone-input {
  width: 100% !important;
  border: 1px solid;
  border-radius: 5px;
  padding: 20px;
}

.option-select button {
  background-color: #f6f1ee;
  border: 1px solid #efe1d9;
  min-width: 45px;
  min-height: 35px;
  border-radius: 0;
  margin-right: 5px;
  padding: 10px;
}

.option-select button:hover {
  background-color: #f6f1ee;
  border: 1px solid #866559;
}

.option-select button.selected {
  background-color: #c17f67;
  border: 1px solid #866559;
  color: white;
}

.option-select button.selected img {
  filter: invert(1);
}

.option-select button img {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}

.option-select button p {
  font-weight: bold;
}

.option-select button.selected p {
  color: white;
}

.react-tel-input .flag-dropdown {
  border: 0 !important;
}

img.diamond-shape-img {
  max-width: 28px;
}

.chakra-ui-dark img.diamond-shape-img {
  filter: invert(1);
}

.react-tel-input .selected-flag {
  padding: 0px 0px 0px 5px !important;
}

.diamond-detail-container table {
  width: 100%;
  border-collapse: collapse;
}

.diamond-detail-container th, .diamond-detail-container td {
  width: 20%;
  word-wrap: break-word;
  overflow-wrap: break-word; 
  white-space: normal; 
  padding: 3px 5px;
  font-size: 13px;
}

.diamond-detail-container th {
  padding-bottom: 10px;
  font-size: 14px;
}

.supplier-detail-table th, .supplier-detail-table td {
  width: 20%;
  word-wrap: break-word;
  overflow-wrap: break-word; 
  white-space: normal; 
  padding: 3px 5px;
  font-size: 14px;
}

.link-btn {
  color: #0000EE !important;
  font-weight: 500 !important;
}

a.sidebar-menu-link:hover {
  background-color: #efe1d9;
  display: block;
}

.custom-table td, .custom-table th {
  padding: 5px 10px;
  text-transform: unset;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image, 
.image-gallery-thumbnail, 
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails, 
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left, 
.image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right {
  width: 50px;
}

.diamond-detail-grid-row {
  padding: 8px 10px;
  border-top: 0.5px solid gainsboro;
}

.diamond-detail-grid-row img.diamond-shape-img {
  max-width: 25px;
}

.diamond-media-thumbnail img {
  width: 55px;
  height: 55px;
  object-fit: cover;
}

.diamond-media-thumbnail button {
  width: 55px;
  height: 55px;
  background-color: gainsboro;
}

.diamond-media-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1 / 1; /* Ensures the container is a square */
}

.diamond-media-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Keeps the image aspect ratio intact */
}

.diamond-media-thumbnail, .diamond-media-img {
  cursor: pointer;
}

.diamond-type-badge {
  display: flex !important;;
  height: 15px;
  width: 15px;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
}

.hold-diamond-table img, .table-diamond-img {
  max-width: 50px;
}

.hold-diamond-table th {
  padding: 10px 15px;
}

.hold-diamond-table td {
  padding: 5px 15px;
}

span.sibebar-menu-category {
  cursor: initial;
  color: #9b9b9b;
  font-size: 15px;
  margin: 5px 0px;
  margin-left: 35px;
  width: 100%;
}

.col-align-right > div {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.col-align-center  > div {
  display: flex;
  text-align: center;
  justify-content: center;
}

.sa-detail-container {
  width: 100%;
  padding: 20px;
}

.sa-profile-img {
  width: 70px;
  height: 70px;
  background-color: gainsboro;
  border-radius: 50px;
  margin-right: 20px;
}

.sidebar-container {
  width: 70px;
  z-index: 9999;
}

.sidebar-inner-container {
  padding: 15px 0;
}

a.sidebar-menu-link {
  padding: 7px 0px;
  display: block;
  width: 100%;
}

span.sibebar-menu-category, a.sidebar-menu-link p, .sidebar-container h2.chakra-heading {
  display: none;
}

.sidebar-container:hover {
  width: auto;
}

.sidebar-container:hover span.sibebar-menu-category, 
.sidebar-container:hover a.sidebar-menu-link p,
.sidebar-container:hover h2.chakra-heading {
  display: block;
}

.sidebar-container:hover .sibebar-menu-category-dots, .sidebar-container:hover .sidebar-right-icon {
  display: none;
}

.sidebar-container:hover .sidebar-menu-link-text {
  padding-left: 15px;
  padding-right: 30px;
}

.sidebar-container:hover a.sidebar-menu-link p {
  margin-left: 10px;
}

.chakra-ui-dark img.sidebar-logo {
  filter: invert(1);
}

img.sidebar-logo {
  height: 75px;
  max-width: 100px;
}

.sidebar-container:hover img.sidebar-logo {
  height: 120px;
}

p.chakra-menu__group__title {
  margin: 0px 12px;
  text-align: left;
  font-size: 11px;
  color: gray;
}

.chakra-menu__menuitem {
  font-size: 13px;
}

.sort-by-menu-container > div {
  max-height: 410px;
  overflow-y: scroll;
  min-width: unset !important;
  width: 180px;
}

.chakra-menu__menu-list {
  min-width: unset !important;
}

.sort-by-menu-container hr.chakra-menu__divider {
  margin-top: 0px;
}

.sort-by-menu-container hr.chakra-menu__divider:last-child {
  display: none;
}

/* Landing page css */
.landing-page .chakra-container  {
  max-width: 1000px;
}

.landing-page .landing-header {
  background-color: #2B170E;
  padding: 20px 15px;
}

.landing-page .landing-header img {
  height: 40px;
}

.landing-page .landing-header hr {
  background-color: white;
  color: white;
  height: 35px;
  margin: 0px 40px;
}

.landing-banner-container {
  background-color: #EDCFB5;
  padding: 45px 15px 200px;
  margin-bottom: 110px;
  position: relative;
}

img.landing-page-logo {
  max-width: 80px;
}

.landing-banner-headings {
  padding: 50px 15px 20px;
  z-index: 999;
}

h1.chakra-heading.banner-big-title {
  font-size: 100px;
  line-height: 90px;
  letter-spacing: 10px;
}

h1.chakra-heading.banner-title {
  font-size: 60px;
  letter-spacing: 10px;
}

p.chakra-text.banner-description {
  font-weight: 600;
  font-size: 18px;
  z-index: 999;
  text-align: center;
}

.landing-banner-absolute {
  position: absolute;
  bottom: -125px;
  max-width: 450px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.sidebar-logo-container {
  height: 135px;
}

/* Animate the size, outside */
.pulse:hover, 
.pulse:focus {
  animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 #9B5A1A; }
}

/* Basic button styles */
button.signup-btn {
  border: 2px solid white;
  transition: 0.25s;
  color: white;
  background-color: #9B5A1A;
  border-radius: 50px;
  font-size: 12px;
  max-width: 200px;
  overflow: hidden;
}

button.signup-btn:before {
  content: '';
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
      120deg,
      rgba(255,255,255, 0) 30%,
      rgba(255,255,255, .8),
      rgba(255,255,255, 0) 70%
  );
  top: 0;
  left: -100px;
  animation: shine 3s infinite linear; /* Animation */
}

@keyframes shine {
  0% {left: -100px}
  20% {left: 100%}
  100% {left: 100%}
}

button.signup-btn:hover,
button.signup-btn:focus { 
  border-color: #9B5A1A;
  color: #9B5A1A;
  background-color: rgba(237, 207, 181, 0.5);
}

.landing-banner-absolute button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

hr.chakra-divider.about-us-divider {
  background-color: black;
  width: 1px;
  height: 100px;
  margin-bottom: 25px;
}

h1.chakra-heading.content-title {
  margin-bottom: 10px;
}

p.chakra-text.content-description {
  font-weight: 300;
  font-size: 17px;
}

.diamond-feed-container {
  margin-top: -50px;
  margin-bottom: 35px;
}

img.about-us1-img {
  max-width: 800px;
  margin-top: 35px;
  width: 100%;
}

img.curve-img {
  width: 100px;
}

.diamond-feed-container .content-title {
  margin-right: 35px;
}

.diamond-feed-container .diamond-feed-description {
  margin-top: 120px;
  max-width: 450px;
  margin-left: 10px;
}

.benefit-container, .our-product-container {
  margin-bottom: 50px;
}

.benefit-item {
  background-color: #EDCFB5;
  max-width: 450px;
  margin: 5px;
  border-radius: 28px;
  padding: 35px;
}

img.benefit-img {
  max-width: 50px;
  margin-bottom: 8px;
}

p.chakra-text.content-description.benefit-title {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 5px;
}

.benefit-col-2 {
  margin-top: 85px;
}

.our-product-item {
  padding: 15px;
  border: 1px solid black;
  max-width: 435px;
  border-radius: 20px;
  flex: 1;
}

.our-product-item img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
  margin-bottom: 15px;
}

p.chakra-text.content-description.our-product-title {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 5px;
}

.step-container {
  padding: 50px 0px;
  background-color: #EDCFB5;
}

.step-item {
  width: 100%;
  max-width: 900px;
}

.step-item > div {
  padding: 0 35px;
}

.step-item img {
  max-width: 380px;
  width: 100%;
}

.step-item img.step-item-last-img {
  max-width: 350px;
}

p.chakra-text.content-description.step-title {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 10px;
  z-index: 999;
}

.step-description {
  z-index: 999;
}

.step-description-2, .step-title-2 {
  text-align: right;
  width: 100%;
}

p.chakra-text.step-index {
  font-weight: 700;
  color: white;
  position: absolute;
  font-size: 180px;
  z-index: 0;
  line-height: normal;
}

.step-index-1 {
  top: -105px;
  left: -5px;
}

.step-index-2 {
  top: -70px;
  right: -25px;
}

.step-item {
  margin-bottom: 50px;
}

.step-item.last {
  margin-bottom: 0px;
}

.step-index-3 {
  left: -20px;
  top: -58px;
}

.partner-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

p.content-description.partner-title {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 5px;
}

.partner-item img {
  height: 60px;
  margin-bottom: 5px;
}

.partner-item {
  width: 100%;
  max-width: 320px;
}

.request-demo-container {
  padding: 35px 15px;
}

.request-demo-container img {
  width: 100%;
  max-width: 235px;
}

.request-demo-item {
  padding: 0 25px;
}

.request-deomo-description {
  margin-bottom: 60px;
}

p.chakra-text.content-description.blog-title {
  font-weight: bold;
}

p.chakra-text.content-description.blog-author {
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 5px;
}

a.blog-link {
  color: #EDCFB5;
  transition: 0.25s;
}

a.blog-link:hover, a.blog-link:focus {
  color: #9A7562;
}

.blog-container {
  padding: 25px 15px 50px;
}

.mobile-app-title-container {
  margin-top: 25px;
}

.mobile-app-title-container .content-title {
  z-index: 999;
  font-size: 60px;
}

img.mobile-app-img {
  width: 100%;
  max-width: 350px;
  margin-top: -85px;
}

.mobile-app-container {
  padding-top: 235px;
  background-color: #EDCFB5;
  padding-bottom: 50px;
  margin-top: -185px;
}

.mobile-app-item {
  width: 100%;
  max-width: 650px;
  margin: auto;
}

img.app-store-img {
  width: 100%;
  max-width: 145px;
}

.footer-container {
  background-color: #2c170e;
  padding: 25px 15px;
}

.footer-container img.landing-page-logo {
  max-width: 75px;
}

img.signin-page-logo {
  width: 100%;
  max-width: 75px;
  margin: auto;
  margin-bottom: 20px;
  margin-top: -55px;
}

/* For screens smaller than 1200px */
@media (max-width: 1199px) {
  /* CSS styles for lg */
  .sidebar-container {
    width: auto;
  }

  .sidebar-container img.sidebar-logo {
    height: 120px;
  }
  
  .sidebar-container span.sibebar-menu-category, 
  .sidebar-container a.sidebar-menu-link p,
  .sidebar-container h2.chakra-heading {
    display: block;
  }
  
  .sidebar-container .sibebar-menu-category-dots, .sidebar-container .sidebar-right-icon {
    display: none;
  }
  
  .sidebar-container .sidebar-menu-link-text {
    padding-left: 15px;
    padding-right: 30px;
  }
  
  .sidebar-container a.sidebar-menu-link p {
    margin-left: 10px;
  }
}

/* For screens smaller than 992px */
@media (max-width: 991px) {
  /* CSS styles for md */
  .landing-page .landing-header img {
    height: 30px;
  }

  .landing-page .landing-header hr {
    margin: 0px 30px;
  }

  .benefit-item {
    padding: 20px;
  }

  .our-product-container .our-product-item:first-child, .blog-container .our-product-item:first-child {
    margin-right: 5px;
  }

  .our-product-container .our-product-item:last-child, .blog-container .our-product-item:last-child {
    margin-left: 5px;
  }

  .our-product-item img {
    height: 210px;
  }
}

/* For screens smaller than 768px */
@media (max-width: 767px) {
  /* CSS styles for sm */
  .landing-page .landing-header img {
    height: 25px;
  }

  .landing-page .landing-header hr {
    margin: 0px 25px;
  }

  h1.chakra-heading.banner-big-title {
    font-size: 75px;
  }

  .landing-banner-absolute {
    bottom: -90px;
    max-width: 420px;
  }

  .landing-banner-headings {
    padding: 30px 15px 20px;
  }

  .diamond-feed-container {
    margin-top: -10px;
    margin-bottom: 35px;
  }

  .landing-page h1 {
    font-size: 30px;
}
  
  p.chakra-text.content-description {
    font-size: 15px;
  }

  p.chakra-text.content-description.benefit-title {
    font-size: 18px;
    line-height: 22px;
  }

  img.benefit-img {
    max-width: 45px;
  }

  .benefit-item {
    padding: 20px 15px;
  }

  .our-product-item img {
    height: 170px;
  }

  p.chakra-text.content-description.our-product-title {
    font-size: 18px;
  }

  p.chakra-text.step-index {
    font-size: 150px;
  }

  .step-index-1 {
    top: -60px;
  }

  .step-item > div {
    padding: 0 15px;
  }

  .step-index-2 {
    top: -60px;
    right: -30px;
  }

  .step-index-3 {
    left: -10px;
    top: -60px;
  }

  .request-demo-container {
    padding: 0px 15px 20px;
  }

  .blog-container {
    padding: 25px 15px 35px;
  }
}

/* For screens smaller than 576px */
@media (max-width: 575px) {
  /* CSS styles for xs */
  .landing-page .landing-header hr {
    margin: 0px 3vw;
  }

  .landing-page .landing-header img {
    height: 15px;
  }

  .landing-page .landing-header hr {
    margin: 0px 8px;
    height: 16px;
  }

  h1.chakra-heading.banner-big-title {
    font-size: 40px;
    line-height: 35px;
    letter-spacing: 5px;
  }

  h1.chakra-heading.banner-title {
    font-size: 30px;
    letter-spacing: 5px;
  }

  .landing-banner-container {
    padding: 25px 15px 200px;
    margin-bottom: 82px;
  }

  .landing-banner-absolute {
    bottom: -130px;
    padding: 0 15px;
    max-width: 320px;
  }

  .landing-banner-container {
    padding: 25px 15px 100px;
  }

  img.landing-page-logo, .footer-container img.landing-page-logo {
    max-width: 58px;
  }

  hr.chakra-divider.about-us-divider {
    height: 70px;
  }

  .landing-page h1 {
    font-size: 18px;
  }

  p.chakra-text.content-description {
    font-size: 12px;
  }

  img.curve-img {
    width: 70px;
  }

  .diamond-feed-container .diamond-feed-description {
    margin-top: 60px;
  }

  .diamond-feed-col-1 {
    max-width: 115px;
  }

  .diamond-feed-container .content-title {
    margin-right: -25px;
    text-align: left;
  }

  img.benefit-img {
    max-width: 30px;
  }

  p.chakra-text.content-description.benefit-title {
    font-size: 14px;
    line-height: 16px;
  }

  .benefit-item {
    padding: 15px;
    border-radius: 15px;
    margin: 3px;
}

  .benefit-container, .our-product-container {
    margin-bottom: 35px;
  }

  .our-product-item {
    padding: 5px;
    border-radius: 13px;
  }

  .our-product-item img {
    height: 28vw;
    margin-bottom: 5px;
    border-radius: 13px;
  }

  p.chakra-text.content-description.our-product-title {
    font-size: 15px;
    line-height: 17px;
    text-align: center;
  }

  .step-item > div {
    padding: 0 5px;
  }

  p.chakra-text.step-index {
    font-size: 85px;
  }

  p.chakra-text.content-description.step-title {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .step-index-1 {
    top: -35px;
  }

  .step-index-2 {
    top: -38px;
    right: -25px;
  }

  .step-index-3 {
    left: -7px;
    top: -45px;
  }

  .partner-item img {
    height: 42px;
    margin-bottom: 5px;
  }

  .partner-container {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .request-demo-container > div {
    flex-direction: column;
  }

  .request-demo-container img {
    transform: translateX(-18px);
  }

  .request-demo-item h1 {
    text-align: center;
  }

  .request-demo-item {
    align-items: center;
  }

  .request-deomo-description {
    margin-bottom: 25px;
    text-align: center;
  }

  .mobile-app-title-container .content-title {
    font-size: 30px;
  }

  img.mobile-app-img {
    max-width: 250px;
    margin-top: -55px;
  }

  .mobile-app-title-container {
    margin-top: 0px;
  }

  img.app-store-img {
    width: 100%;
    max-width: 105px;
  }
}

@media (max-width: 399px) {
  .landing-banner-absolute {
    bottom: -98px;
    padding: 0 15px;
    max-width: 320px;
  }

  .landing-banner-container {
    padding: 25px 15px 100px;
  }

  .benefit-item {
    padding: 10px;
  } 

  .our-product-item img {
    height: 100px;
  }
}